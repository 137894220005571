var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"padless":""}},[_c('v-card',{staticClass:"flex primary",attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"secondary"},[_c('v-spacer'),(!_vm.$vuetify.theme.dark)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":_vm.darkMode}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-moon-waxing-crescent")])],1)]}}],null,false,3478940519)},[_c('span',[_vm._v(_vm._s(_vm.$t("Navbar.DarkMode")))])]):_vm._e(),(_vm.$vuetify.theme.dark)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":_vm.darkMode}},on),[_c('v-icon',{staticClass:"r-3"},[_vm._v("mdi-weather-sunny")])],1)]}}],null,false,3862142900)},[_c('span',[_vm._v(_vm._s(_vm.$t("Navbar.DarkMode")))])]):_vm._e(),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(" mdi-translate ")])],1)]}}])},[_c('v-list',_vm._l((_vm.languages),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleLanguage(item.Code)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.Language))])],1)}),1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"icon":"","small":"","to":'/metrics'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("Footer.metrics")))])])],1),_c('v-card-text',{staticClass:"py-2 text-center"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v(_vm._s(_vm.$t("Footer.company")))]),_vm._v(" — "+_vm._s(_vm.$t("Footer.powered"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }